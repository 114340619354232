import CmsModuleList from '@/cmsComponents/CmsModuleList';
import HomePageCvp from '@/components/HomePageCvp/HomePageCvp';
import CMSContainer from '@/modules/CMSContainer';
import HomePageBanner from '@/modules/HomePageBanner';
import { DynamicPageData, DynamicPageType } from '@/types/cms';
import { getHomepagePropositionFlag } from '@/utils/getServerFeatureFlag';
import { GetServerSidePropsContext, GetServerSidePropsResult } from 'next';
import { NextSeo } from 'next-seo';
import React from 'react';
import { getDynamicPageData } from 'utils/getDynamicPageData';

const HomePage = ({ data, pageData, propositionModuleTest }: DynamicPageData) => {
  const defaultTitle = 'Search over 2,000 Doctor Jobs in Australia and New Zealand.';
  const defaultDescription = 'Discover the right doctor job for you in Australia or New Zealand';

  return (
    <CMSContainer>
      <NextSeo
        canonical={pageData.canonical_url}
        title={pageData.meta_title || defaultTitle}
        description={pageData.meta_description || defaultDescription}
        noindex={!pageData.index}
        nofollow={!pageData.index}
        openGraph={{
          title: pageData.meta_title || defaultTitle,
          description: pageData.meta_description || defaultDescription,
        }}
      />
      <HomePageBanner title={defaultTitle} />
      {propositionModuleTest && <HomePageCvp />}
      <CmsModuleList modules={data} />
    </CMSContainer>
  );
};

export async function getServerSideProps(
  context: GetServerSidePropsContext,
): Promise<GetServerSidePropsResult<DynamicPageData>> {
  const {
    modules = [],
    pageData,
    error,
  } = await getDynamicPageData(DynamicPageType.Home_Page, context);

  const propositionModuleTest = await getHomepagePropositionFlag(context);

  if (error) {
    return { notFound: true };
  }

  if (pageData?.redirect_url) {
    return {
      redirect: {
        destination: pageData.redirect_url,
        permanent: true,
      },
    };
  }

  return {
    props: {
      data: propositionModuleTest ? modules.slice(1) : modules,
      pageData,
      propositionModuleTest,
    },
  };
}

export default HomePage;
