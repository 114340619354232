import BackgroundBannerImage from '@/components/BackgroundBannerImage';
import JobSearchBox from '@/modules/JobSearchBox';
import React from 'react';

import styles from './HomePageBanner.module.scss';

export type HomePageBannerProps = {
  title: string;
};

const HomePageBanner = ({ title }: HomePageBannerProps) => {
  return (
    <div className={styles.container} data-testid={'home-banner-container'}>
      <BackgroundBannerImage className={styles.bgImage} />
      <h1 className={styles.heading} data-testid={'home-banner-heading'}>
        {title}
      </h1>
      <div className={styles.form}>
        <JobSearchBox />
      </div>
    </div>
  );
};

export default HomePageBanner;
