import Image from 'next/image';

// Will use styles in refactor
// import styles from './style.module.scss';

interface BackgroundBannerImageProps {
  layout?: 'fill' | 'fixed' | 'intrinsic' | 'responsive';
  objectFit?: 'cover' | 'contain' | 'scale-down' | 'fill' | 'none';
  className?: string;
  alt?: string;
  priority?: boolean;
}

export const BackgroundBannerImage = ({
  layout = 'fill',
  objectFit = 'cover',
  alt = '',
  priority = true,
  className,
}: BackgroundBannerImageProps) => {
  // TODO: update blue_banner.svg to not be massive and refactor this component to use <BlueBanner /> instead.
  //       Current implementation clashes with loki visual regression testing - stopping us from using this component in our stories
  return (
    <Image
      className={className}
      src="/blue_banner.svg"
      layout={layout}
      objectFit={objectFit}
      alt={alt}
      priority={priority}
    />
  );
};

export default BackgroundBannerImage;
