import { appClientConfig } from '@/app/utils/appClientConfig';
import { LinkElement } from '@/cmsComponents/LinkElement';

import Typography from '@material-ui/core/Typography';

import styles from './HomePageCvp.module.scss';

const HomePageCvp = () => {
  const onBoardingLink = `${appClientConfig.practitionerProfileUrl}/register`;
  return (
    <div className={styles.homePageCvpContainer}>
      <div className={styles.homePageCvpContainerBg}>
        <Typography variant="h5" component="h3" className={styles.title}>
          Join our network of over 80,000 doctors
        </Typography>
        <div className={styles.cvpTextContainer}>
          <p className={styles.cvpText}>
            Get personalised job matches with the pay you want and get full clinician support from
            our Solution Specialists. Join us now, create your digital profile, save time on
            paperwork and get placed into relevant work faster.
          </p>
        </div>
        <div>
          <LinkElement
            trackAction="New homepage CVP button click"
            buttonClassName={styles.button}
            linkText={'Join us'}
            link={onBoardingLink}
          />
        </div>
      </div>
    </div>
  );
};

export default HomePageCvp;
