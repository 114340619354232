import { WithChildren } from '@/types/withChildren';
import dynamic from 'next/dynamic';
import React from 'react';

import Footer from 'modules/Footer';
import LandingHeader from 'modules/LandingHeader';

import styles from './style.module.scss';

const CookieConsentPrompt = dynamic(() => import('@/app/components/CookieConsentPrompt'));

export const CMSContainer: React.FC<WithChildren> = ({ children }) => {
  return (
    <div className={styles.outsideContainer}>
      <LandingHeader />
      {children}
      <Footer />
      <CookieConsentPrompt />
    </div>
  );
};

export default CMSContainer;
